
<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import store from "@/state/store";
var state = store._modules.root._children.auth.state;
export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      Users: [],
      page: 1,
      limit: 10,
      tot_pages: 0,
      searchUsers: null,
      UsersSearchMode: false,
      searchModel: "",
      state: state,
    };
  },
  methods: {
    addUsers() {
      console.log("**********####", this.state);
      var data = {
        title: "popups.addUsers",
        inputs: [
          { model: "username", type: "text", label: "users.username" },
          { model: "email", type: "text", label: "users.email" },
          { model: "password", type: "text", label: "users.password" },
          // { model: "phone", type: "text", label: "users.phone" },
        ],
        buttons: [
          {
            text: "popups.add",
            closer: true,
            color: "primary",
            handler: (obj) => {
              this.http.post("users", obj).then(() => {
                this.get(this.page);
              });
            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
    editUsers(app) {
      var data = {
        title: "popups.users",
        inputs: [
          {
            model: "username",
            type: "text",
            label: "users.username",
            value: app.username,
          },
          {
            model: "email",
            type: "text",
            label: "users.email",
            value: app.email,
          },
          {
            model: "created_at",
            type: "text",
            label: "users.created_at",
            value: app.created_at,
          },
          {
            model: "password",
            type: "text",
            label: "users.password",
            value: app.password,
          },
          {
            model: "updated_at",
            type: "text",
            label: "users.updated_at",
            value: app.updated_at,
          },
          {
            model: "fullname",
            type: "text",
            label: "users.fullname",
            value: app.fullname,
          },
          {
            model: "refreshtoken",
            type: "text",
            label: "users.refreshtoken",
            value: app.refreshtoken,
          },
          {
            model: "phone",
            type: "text",
            label: "users.phone",
            value: app.phone,
          },
        ],
        buttons: [
          {
            text: "popups.edit",
            closer: true,
            color: "primary",
            handler: (obj) => {
              this.http.put("users", app.id, obj).then(() => {
                this.get(this.page);
              });
            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
    updateUsers(app) {
      var data = {
        title: "popups.editfile",
        inputs: [
          { model: "username", type: "text", label: "users.username" },
          { model: "email", type: "text", label: "users.email" },
          { model: "password", type: "text", label: "users.password" },
          // { model: "phone", type: "text", label: "users.phone" },
        ],
        buttons: [
          {
            text: "popups.edit",
            closer: true,
            color: "primary",
            handler: (obj) => {
              obj.emp_id = app.id;
              this.http.do("users", obj).then(() => {
                this.get(this.page);
              });
            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
    search() {
      this.UsersSearchMode = true;
      this.tot_pages = 0;
      this.http
        .post("users/search", {
          search: this.searchModel,
          limit: this.limit,
          page: this.page,
          col: "",
        })
        .then((res) => {
          this.Users = res.data;
          console.log("######## files", this.Users);
        });
    },
    cancelappsearchMode() {
      this.searchModel = "";
      this.UsersSearchMode = false;
      this.get(this.page);
    },

    getUsers() {
      this.http.get("users").then((res) => {
        this.Users = res.data;
      });
    },
    deleteUsers(app) {
      var data = {
        title: "popups.deletefile",
        msg: "popups.delmessage",
      };
      this.popup.confirm(data).then((resp) => {
        if (resp) {
          console.log("###");
          this.http.delete("users", app.id).then(() => {
            console.log("############################3");
            this.get(this.page);
          });
        }
      });
    },

    get(page) {
      console.log(page);
      this.http
        .post("users/paginate", {
          limit: this.limit,
          page: page,
        })
        .then((res) => {
          this.page = page;
          this.tot_pages = Math.ceil(res.tot / this.limit);
          this.Users = res.data;
        });
    },
  },
  created() {
    this.get(1);
    this.getUsers();
  },
};
</script>

<template>
  <Layout>
    <PageHeader
      :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
      :title="$t('menu.menuitems.users.text')"
    />
    <div class="row">
      <div class="col-6">
        <div class="search-box chat-search-box w-50">
          <div class="position-relative">
            <input
              @keyup="search()"
              type="text"
              class="form-control fa-lg text-light"
              :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
              :placeholder="$t('popups.search')"
              v-model="searchModel"
              style="background-color: #2a3042 !important"
            />
            <i class="bx bx-search-alt search-icon text-light"></i>
          </div>
        </div>
      </div>
      <div class="col-6" style="display: flex; justify-content: flex-end">
        <button
          class="btn-close text-light"
          v-if="UsersSearchMode"
          @click="cancelappsearchMode()"
        ></button>
        <button
          type="button"
          @click="addUsers()"
          class="btn btn-light float-end mb-4"
        >
          <span
            class="bx bxs-plus-circle float-end fa-2x"
            style="color: #2a3042 !important"
          ></span>
        </button>
      </div>
    </div>

    <table :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="table">
      <thead>
        <tr
          class="text-light text-center"
          style="background-color: #2a3042 !important"
        >
          <th scope="col">{{ $t("users.id") }}</th>
          <th scope="col">{{ $t("users.username") }}</th>
          <th scope="col">{{ $t("users.fullname") }}</th>
          <th scope="col">{{ $t("users.phone") }}</th>
          <th scope="col">{{ $t("users.email") }}</th>
          <th scope="col">{{ $t("users.operations") }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(app, index) in Users" :key="app" class="text-center">
          <td>{{ index + 1 }}</td>
          <td>{{ app.username }}</td>
          <td>{{ app.fullname }}</td>
          <td>{{ app.phone }}</td>
          <td>{{ app.email }}</td>

          <td class="d-flex">
            <router-link
              class="btn btn-success mx-1"
              :to="'/user-roles/' + app.id"
              href="javascript: void(0);"
              role="button"
              >{{ $t("popups.Access") }}</router-link
            >
            <a
              class="btn btn-primary mx-1"
              @click="editUsers(app)"
              href="javascript: void(0);"
              role="button"
              >{{ $t("popups.edit") }}</a
            >
            <a
              class="btn btn-danger"
              href="javascript: void(0);"
              role="button"
              @click="deleteUsers(app)"
              >{{ $t("popups.delete") }}</a
            >
          </td>
        </tr>
      </tbody>
    </table>
    <!-- end  table -->
    <!-- Button trigger modal -->

    <div v-if="tot_pages > 0">
      <ul class="paginate paginate-rounded justify-content-center mb-2">
        <!-- Previous Page Link -->
        <li class="page-item" :class="{ disabled: page === 1 }">
          <a
            class="page-link"
            href="javascript: void(0);"
            @click="get(page - 1)"
            aria-label="Previous"
          >
            <i class="mdi mdi-chevron-left"></i>
          </a>
        </li>

        <!-- Page Number Links -->
        <li
          class="page-item"
          :class="{ active: p === page }"
          v-for="p in totalPages"
          :key="p"
        >
          <a class="page-link" href="javascript: void(0);" @click="get(p)">{{
            p
          }}</a>
        </li>

        <!-- Next Page Link -->
        <li class="page-item" :class="{ disabled: page === totalPages }">
          <a
            class="page-link"
            href="javascript: void(0);"
            @click="get(page + 1)"
            aria-label="Next"
          >
            <i class="mdi mdi-chevron-right"></i>
          </a>
        </li>
      </ul>
    </div>

    <!--end-->
  </Layout>
</template>

<style scoped>
.btn-close {
  position: relative;
  right: 317px;
  top: 6px;
  font-size: 15px;
}

.btn-close {
  position: relative;
  right: 317px;
  top: 6px;
  font-size: 15px;
}

.paginate {
  display: flex;
  justify-content: center;
  padding-left: 0;
  list-style: none;
}

.page-item {
  display: inline-block;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6;
  text-decoration: none;
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
  border-color: #dee2e6;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
</style>




